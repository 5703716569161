import React, { useState, useEffect } from 'react';
import { fetchStockData, updateMood } from './StockDataService';
import WaveVisualization from './WaveVisualization';
import './StockMoodVisualization.css';

const StockMoodVisualization = ({ isDaytime }) => {
  const [mood, setMood] = useState({
    waveHeight: 0.5,
    waveFrequency: 0.5,
    marketPerformance: 0
  });

  useEffect(() => {
    const getData = async () => {
      const data = await fetchStockData();
      if (data) {
        const newMood = updateMood(data);
        setMood(newMood);
      }
    };
    getData();
    const interval = setInterval(getData, 5 * 60 * 1000); // Fetch every 5 minutes
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="visualization-container">
      <WaveVisualization mood={mood} isDaytime={isDaytime} />
    </div>
  );
};

export default StockMoodVisualization;
