import React from 'react';
import './PersonalInfo.css';

const PersonalInfo = () => {
  const timelineData = [
    { year: 'Late 90s', event: 'The internet and I met, one of my first loves' },
    { year: 'Early 2010s', event: 'BFA Graphic Design and Multimedia Studies @ Northeastern University' },
    { year: '1 year', event: 'Frontend development @ The Boston Globe' },
    { year: '2 years', event: 'Designer, frontend development @ Airbo' },
    { year: '3 years', event: 'Designer, frontend development → Design Director @ thoughtbot' },
    { year: '6 years so far', event: 'First and Senior → Principal Product Designer @ Iterable' }
  ];

  const contactInfo = [
    { medium: 'Email', link: 'mailto:hey@conchan.site' },
    { medium: 'PGP', link: 'pgp.txt' },
    { medium: 'Github', link: 'https://github.com/conchan' },
    { medium: 'Dribbble', link: 'https://dribbble.com/conchan' }
  ];

  return (
    <div
      className="personal-info"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <h1 className="name">Constance <br/>Chan</h1>
      <h3 className="description">A designer of this digital world</h3>
      <ul className="timeline">
        {timelineData.map((item, index) => (
          <li
            className="timeline-item"
            key={index}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.1 }}
          >
            <p>
              <strong>{item.year}:</strong> {item.event}
            </p>
          </li>
        ))}
      </ul>

      <ul className="contact-info">
        {contactInfo.map((item, index) => (
          <li
            className="contact-method"
            key={index}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.1 }}
          >
            <a href={item.link}>{item.medium}</a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PersonalInfo;