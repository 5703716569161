const SYMBOL = '^GSPC'; // S&P 500 index

export const fetchStockData = async () => {
  try {
    const response = await fetch('/stockData.json');
    const data = await response.json();

    return {
      price: data.price,
      previousClose: data.previousClose,
      volume: data.volume
    };

  } catch (error) {
    console.error('Error fetching stock data:', error);
    return null;
  }
};

export const updateMood = (data) => {
  if (!data) {
    return {
      waveHeight: 0.5,
      waveFrequency: 0.5,
      marketPerformance: 0
    };
  }

  const percentChange = (data.price - data.previousClose) / data.previousClose;
  const maxVolume = 10000000000; // Adjust this based on typical S&P 500 volumes

  const waveHeight = Math.min(1, Math.max(0.2, Math.abs(percentChange) * 20)); // Scale wave height based on market performance
  const waveFrequency = Math.min(1, Math.max(0.2, data.volume / maxVolume)); // Scale wave frequency based on volume

  return {
    waveHeight,
    waveFrequency,
    marketPerformance: percentChange
  };
};
