import { useEffect, useState } from 'react';

const GoatCounter = () => {
  const [location, setLocation] = useState(window.location.pathname + window.location.search + window.location.hash);
  const [scriptLoaded, setScriptLoaded] = useState(false);

  useEffect(() => {
    const script = document.createElement('script');
    script.async = true;
    script.src = '//gc.zgo.at/count.js';
    script.setAttribute('data-goatcounter', 'https://conchan.goatcounter.com/count');
    
    script.onerror = () => {
      console.warn('GoatCounter script failed to load. Analytics may not be working.');
      setScriptLoaded(false);
    };
    
    script.onload = () => {
      setScriptLoaded(true);
    };

    document.body.appendChild(script);

    const handleLocationChange = () => {
      setLocation(window.location.pathname + window.location.search + window.location.hash);
    };

    window.addEventListener('popstate', handleLocationChange);

    return () => {
      document.body.removeChild(script);
      window.removeEventListener('popstate', handleLocationChange);
    };
  }, []);

  useEffect(() => {
    if (scriptLoaded && window.goatcounter && typeof window.goatcounter.count === 'function') {
      window.goatcounter.count({
        path: location,
      });
    }
  }, [location, scriptLoaded]);

  return null;
};

export default GoatCounter;